.content-container {
    max-width: 42em;
  }
  
  /*
   * Social Media
   */
  .item.social>a {
    font-size: 20px;
    width: 36px;
    height: 36px;
    line-height: 36px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4);
    margin: 0 8px;
    color: #fff;
    opacity: 0.75;
  }
  
  .item.social>a:hover {
    opacity: 0.9;
  }

  #pageContent {
    animation: fade 0.9s ease-in;
    -webkit-animation: fade 2.9s ease-in;
  }

  @keyframes fade {
    0% {
      opacity: 0;
    }
  }